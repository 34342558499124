var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"width":"7%"}},[_c('center',[_vm._v("TIPO")])],1),_c('th',{attrs:{"width":"21%"}},[_c('center',[_vm._v("INGRESO/ GASTO")])],1),_c('th',{attrs:{"width":"21%"}},[_c('center',[_vm._v("SUBCATEGORÍA")])],1),_c('th',{attrs:{"width":"21%"}},[_c('center',[_vm._v("PROVEEDOR")])],1),_c('th',{attrs:{"width":"10%"}},[_c('center',[_vm._v("MOVIMIENTOS DEL MES "+_vm._s(_vm.mes))])],1),_c('th',{attrs:{"width":"10%"}},[_c('center',[_vm._v("PROYECCIÓN")])],1),_c('th',{attrs:{"width":"10%"}},[_c('center',[_vm._v("RESULTADOS DEL MES")])],1)])]),_c('tbody',[_vm._l((_vm.dataTable),function(item,index){return [_c('tr',{key:'row-' + index,class:{
              categoria: true,
              'text-red': item.diferencia < 0,
            }},[_c('td',{attrs:{"colspan":"4"}},[_c('v-btn',{attrs:{"icon":"","color":"default"},on:{"click":function($event){item.tipoflag = !item.tipoflag}}},[(!item.tipoflag)?_c('v-icon',[_vm._v("mdi-plus")]):_c('v-icon',[_vm._v("mdi-minus-thick")])],1),_vm._v(" "+_vm._s(item.categoria)+" ")],1),_c('td',[_c('center',[_vm._v(_vm._s(item.monto))])],1),_c('td',[_c('center',[_vm._v(_vm._s(item.proyeccion))])],1),_c('td',[_c('center',[_vm._v(_vm._s(item.diferencia))])],1)]),_vm._l((item.tipo),function(subItem,subIndex){return [(item.tipoflag)?_c('tr',{key:'subdetail-' + index + '-' + subIndex,class:{ tipo: true, 'text-red': subItem.diferenciaTipo < 0 }},[_c('td'),_c('td',[_c('v-btn',{attrs:{"icon":"","color":"default"},on:{"click":function($event){subItem.subtipoflag = !subItem.subtipoflag}}},[(!subItem.subtipoflag)?_c('v-icon',[_vm._v("mdi-plus")]):_c('v-icon',[_vm._v("mdi-minus-thick")])],1),_vm._v(" "+_vm._s(subItem.tipo)+" ")],1),_c('td',{attrs:{"colspan":"2"}}),_c('td',[_c('center',[_vm._v(_vm._s(subItem.montoTipo))])],1),_c('td',[_c('center',[_vm._v(_vm._s(subItem.proyeccionTipo))])],1),_c('td',[_c('center',[_vm._v(_vm._s(subItem.diferenciaTipo))])],1)]):_vm._e(),_vm._l((subItem.subtipo),function(stipo,subIndexTipo){return [(subItem.subtipoflag && item.tipoflag)?_c('tr',{key:'subdetail-' + index + '-' + subIndex + '-' + subIndexTipo,class:{
                  subtipo: true,
                  'text-red': stipo.diferenciaSubTipo < 0,
                }},[_c('td',{attrs:{"colspan":"2"}}),_c('td',[_c('v-btn',{attrs:{"icon":"","color":"default"},on:{"click":function($event){stipo.costoflag = !stipo.costoflag}}},[(!stipo.costoflag)?_c('v-icon',[_vm._v("mdi-plus")]):_c('v-icon',[_vm._v("mdi-minus-thick")])],1),_vm._v(" "+_vm._s(stipo.subtipo)+" ")],1),_c('td'),_c('td',[_c('center',[_vm._v(_vm._s(stipo.montoSubTipo))])],1),_c('td',[_c('center',[_vm._v(_vm._s(stipo.proyeccionSubTipo))])],1),_c('td',[_c('center',[_vm._v(_vm._s(stipo.diferenciaSubTipo))])],1)]):_vm._e(),_vm._l((stipo.costo),function(cost,IndexCost){return [(
                    stipo.costoflag && subItem.subtipoflag && item.tipoflag
                  )?_c('tr',{key:'subdetail-' +
                    index +
                    '-' +
                    subIndex +
                    '-' +
                    subIndexTipo +
                    '-' +
                    IndexCost,class:{ costo: true, 'text-red': cost.result < 0 }},[_c('td',{attrs:{"colspan":"3"}}),_c('td',[_vm._v(_vm._s(cost.proveedor))]),_c('td',[_c('center',[_vm._v(_vm._s(parseFloat(cost.monto).toFixed(2)))])],1),_c('td',[_c('center',[_vm._v(" "+_vm._s(parseFloat(cost.proyeccion).toFixed(2))+" ")])],1),_c('td',[_c('center',[_vm._v(_vm._s(parseFloat(cost.result).toFixed(2)))])],1)]):_vm._e()]})]})]})]}),_c('tr',{class:{
            totales: true,
          }},[_c('td',{attrs:{"colspan":"4"}},[_vm._v("TOTALES")]),_c('td',[_c('center',[_vm._v(" "+_vm._s(parseFloat(_vm.calculateMonto()).toFixed(2))+" ")])],1),_c('td'),_c('td',[_c('center',[_vm._v(" "+_vm._s(parseFloat(_vm.calculateDiferencia()).toFixed(2))+" ")])],1)])],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }